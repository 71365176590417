$product-variations-carousel-link-color: color(dark);
$product-variations-carousel-link-disabled-background: transparent;
$product-variations-carousel-link-disabled-color: color(text-secondary);
$product-variations-carousel-link-background: transparent;
$product-variations-carousel-link-hover-background: color(primary-active);
$product-variations-carousel-link-hover-color: color(dark);
$product-variations-carousel-link-font: #{rem-calc(12)} / 1.25 $font-tertiary;
$product-variations-carousel-link-height: rem-calc(70);
$product-variations-carousel-link-min-width: rem-calc(120);
$product-variations-carousel-link-min-width--large: 0;
$product-variations-carousel-link-width--large: 100%;
$product-variations-carousel-link-border: 1px solid color(border);
$product-variations-carousel-link-hover-border: 1px solid color(warning);
$product-variations-carousel-link-hover-background: $product-variations-carousel-link-background;
$product-variations-carousel-link-selected-color: color(dark);
$product-variations-carousel-link-selected-background: transparent;
$product-variations-carousel-link-selected-border: 1px solid color(warning);

@import "@lora/05-components/product/product-variations-carousel";