// Label
$product-availability-label-font: #{rem-calc(14)} / 1.25 $font-primary;
$product-availability-label-font--large: $product-availability-label-font;
$product-availability-label-text-transform: initial;
// Estimated Delivery Date
$product-availability-estimated-delivery-date-font: $product-availability-label-font;
$product-availability-estimated-delivery-date-font--large: $product-availability-label-font--large;
$product-availability-estimated-delivery-date-text-transform: initial;
$product-availability-message-text-align: center;

@import "@lora/05-components/product/product-availability";