$product-main-padding: rem-calc(27 0 15);
$product-main-padding--large: rem-calc(50 0 33);
$product-main-aside-padding--large: 0 0 0 rem-calc(20);
$product-main-border: none;
$product-main-image-position: relative;
// Product name
$product-main-name-font: 900 #{rem-calc(26)} / 1.25 $font-secondary;
$product-main-name-font--large: 900 #{rem-calc(36)} / 1.25 $font-secondary;
$product-main-name-margin: rem-calc(0 0 7);
$product-main-name-margin--large: rem-calc(0 0 14);
// Product Subtitle
$product-main-subtitle-font: #{rem-calc(12)} / 1.25 $font-primary;
$product-main-subtitle-font--large: #{rem-calc(14)} / 1.25 $font-primary;
$product-main-subtitle-margin: rem-calc(0 0 15);
$product-main-subtitle-margin--large: rem-calc(0 0 18);
// Product Rating
$product-main-rating-margin: 0 auto rem-calc(40);
$product-main-price-font: #{rem-calc(18)} / 1.4 $font-secondary;
$product-main-price-font--large: $product-main-price-font;
$product-main-name-color: color(dark);
$product-main-short-description-color: color(organism-background);
$product-main-actions-margin: rem-calc(0 0 20);
$product-main-info-text-align: center;
$product-main-info-text-align--large: $global-left;
$product-main-aside-padding: 0 rem-calc(15);
$product-main-rating-display: inline-block;

@import "@lora/05-components/product/product-main"